import React, { useState, useEffect, useCallback } from "react";
import ContainerBox from "../../common/hoc/ContainerBox.hoc";
import { useAuth, useNavigation } from "react-auth-navigation";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FormControlLabel, Switch, Button } from "@material-ui/core";
import { api } from "../../../helpers/Api.helper";
import { APIS } from "../../../config/Api.config";
import Select from "react-select";
import Header from "../../common/header/Header.common";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
const AddUsers = (props) => {
  const { userRole } = useAuth();
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { match } = props;
  const { params } = match;
  const [editData, setEditData] = useState();
  const [checkedMtb, setCheckedMtb] = useState(false);
  const [checkedCovid, setCheckedCovid] = useState(false);
  const [checkedGetSMS, setCheckedGetSMS] = useState(false);
  const [checkedGetNotification, setCheckedGetNotification] = useState(false);
  const [checkedViewUltra, setCheckedViewUltra] = useState(false);
  const [checkedViewXdr, setCheckedViewXdr] = useState(false);

  const fetchEditData = async () => {
    if (params.id) {
      let res = await api(APIS.users + parseInt(params.id) + "/");
      setEditData(res?.data);
      setCheckedMtb(res?.data.is_mtb_results);
      setCheckedCovid(res?.data.is_covid_results);
      setCheckedGetSMS(res?.data.get_sms);
      setCheckedGetNotification(res?.data.get_notification);
      setCheckedViewUltra(res?.data.is_ultra_results);
      setCheckedViewXdr(res?.data.is_xdr_results);
    }
  };

  const fetchProvince = async () => {
    const res = await api(APIS.get_province);

    setProvince(res?.data?.results);
  };

  useEffect(() => {
    fetchProvince();
    fetchEditData();
  }, []);

  const getDefaultValue = () => {
    if (editData) return editData?.user_type;
    else return null;
  };

  // const dummy_array=[]
  // if(editData){
  //     dummy_array.push({value:editData.province.map((item)=>{return item.id}),label:editData.province.map((item)=>{return item})})
  // }
  //[{value:"2",label:"Hi"},{value:"3",label:"SO"}]

  //defaultProvince && console.log("Lets Fetch", defaultProvince)
  useEffect(() => {
    setValue("user_type", editData?.user_type);
  }, [editData]);

  //console.log("Province",editData.province[0]);
  const { register, handleSubmit, watch, errors, setValue } = useForm();
  const [province, setProvince] = useState();
  const [selectedProvince, setSelectedProvince] = useState();
  const [usedProvince, setUsedProvince] = useState([]);
  const [district, setDistrict] = useState();
  //const [selectedDistrict, setSelectedDistrict] = useState();
  const [usedDistrict, setUsedDistrict] = useState([]);
  const [municipality, setMunicipality] = useState();
  const [selectedMunicipality, setSelectedMunicipality] = useState();
  const [usedMunicipality, setUsedMunicipality] = useState([]);
  const [ward, setWard] = useState();
  const [selectedWard, setSelectedWard] = useState();
  const [usedWard, setUsedWard] = useState();
  const [healthFacility, setHealthFacility] = useState();
  const [usedHealthFacility, setUsedHealthFacility] = useState([]);
  const [username, setUsername] = useState();
  const [editedFirstName, setEditedFirstName] = useState();
  const [editedEmail, setEditedEmail] = useState();
  const [type, setType] = useState("password");

  const fetchDistrict = async () => {
    if (usedProvince && usedProvince.length > 0) {
      let test_array = [];
      for (let i = 0; i < usedProvince.length; i++) {
        const res = await api(APIS.get_district + usedProvince[i].value + "/");
        // console.log("<<<<<<<<<<<<<<<<<<<<<<<<<", res.data)
        test_array = test_array.concat(res?.data);
        setDistrict(test_array);
      }
      //setUsedDistrict(test_array);
    } else {
      setDistrict([]);
    }
  };

  const fetchMunicipality = async () => {
    if (usedDistrict && usedDistrict.length > 0) {
      let test_array = [];
      for (let i = 0; i < usedDistrict.length; i++) {
        const res = await api(
          APIS.get_municipalites + usedDistrict[i].value + "/"
        );
        test_array = test_array.concat(res?.data);
        setMunicipality(test_array);
      }

      //setMunicipality(res.data)
    } else {
      setMunicipality([]);
    }
  };

  const fetchWard = async () => {
    if (usedMunicipality && usedMunicipality.length > 0) {
      let test_array = [];
      for (let i = 0; i < usedMunicipality.length; i++) {
        const res = await api(APIS.get_ward + usedMunicipality[i].value + "/");
        test_array = test_array.concat(res?.data);
        setWard(test_array);
      }
    } else {
      setWard([]);
    }
  };

  const fetchHealthFacility = async () => {
    if (usedWard && usedWard.length > 0) {
      let test_array = [];
      for (let i = 0; i < usedWard.length; i++) {
        const res = await api(
          APIS.get_health_facility + usedWard[i].value + "/"
        );

        test_array = test_array.concat(res?.data);
        setHealthFacility(test_array);
      }
    } else {
      setHealthFacility([]);
    }
  };

  useEffect(() => {
    fetchDistrict();
    // fetchMunicipality();
    // fetchWard();
    // fetchHealthFacility();
  }, [usedProvince]);

  useEffect(() => {
    fetchMunicipality();
    // fetchWard();
    // fetchHealthFacility();
  }, [usedDistrict]);

  useEffect(() => {
    fetchWard();
    //fetchHealthFacility();
  }, [usedMunicipality]);

  useEffect(() => {
    fetchHealthFacility();
  }, [usedWard]);

  // useEffect(() => {
  //     fetchDistrict();
  //     fetchMunicipality();
  //     fetchWard();
  //     fetchHealthFacility();
  // }, [usedProvince, usedDistrict, usedMunicipality, usedWard, usedHealthFacility])

  const onsubmit = async (data) => {
    // console.log(">>>>", data);
    // return;

    // debugger
    //province_data=[]
    let formData = new FormData();
    editData && formData.append("id", editData.id);
    if (usedProvince && usedProvince.length > 0) {
      for (let i = 0; i < usedProvince.length; i++) {
        formData.append("province", usedProvince[i].value);
      }
    }
    if (usedDistrict && usedDistrict.length > 0) {
      for (let i = 0; i < usedDistrict.length; i++) {
        formData.append("district", usedDistrict[i].value);
      }
    }
    if (usedMunicipality && usedMunicipality.length > 0) {
      for (let i = 0; i < usedMunicipality.length; i++) {
        formData.append("municipality", usedMunicipality[i].value);
      }
    }
    if (usedWard && usedWard.length > 0) {
      for (let i = 0; i < usedWard.length; i++) {
        formData.append("wards", usedWard[i].value);
      }
    }
    if (usedHealthFacility && usedHealthFacility.length > 0) {
      for (let i = 0; i < usedHealthFacility.length; i++) {
        formData.append("hi", usedHealthFacility[i].value);
      }
    }
    formData.append("username", data.username);
    !editData && formData.append("password", data.password);
    // !!editData &&
    //   data.password &&
    //   formData.append("user_password", data.password);
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("user_type", data.user_type);
    formData.append("is_mtb_results", data.is_mtb_results);
    formData.append("is_covid_results", data.is_covid_results);

    formData.append("phone_number", data.phoneNumber);
    formData.append("get_sms", data.canGetSMS);
    formData.append("get_notification", data.canGetNotification);
    formData.append("is_ultra_results", data.canViewUltra);
    formData.append("is_xdr_results", data.canViewXdr);

    //     get_sms
    // get_notification
    // phone_number
    // view_ultra
    // view_xdr

    try {
      let req = editData
        ? await api(APIS.users + editData.id + "/", "PATCH", formData, {
            Accept: "application/json",
            "Content-Type": "multipart/formdata",
          })
        : await api(APIS.users, "POST", formData, {
            Accept: "application/json",
            "Content-Type": "multipart/formdata",
          });

      if (req.status == 201) {
        toast.success("User Added", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        navigate({ pathname: routes["Users"].path });
      } else if (req.status == 200) {
        toast.success("User Updated", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });

        navigate({ pathname: routes["Users"].path });
      } else {
        toast.error(editData ? "User Updating Failed" : "User Adding Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Error Adding", error);
    }

    // try {
    //     let response = await fetch('http://no-such-url');
    //   } catch(err) {
    //     alert(err); // TypeError: failed to fetch
    //   }
  };

  const cancelHandler = () => {
    navigate({ pathname: routes["Users"].path });
  };
  //Multi Select Province Option Set
  let provinceOptions = [];
  let temp_pro = [];
  for (let p in province) {
    temp_pro[p] = {
      value: province[p].id,
      label: province[p].name.substring(2),
    };
    //console.log("Temporary Variable",temp_pro[p])
    provinceOptions.push(temp_pro[p]);
  }

  //Multi Select District OptionSet
  let districtOptions = [];
  let temp_dis = [];
  for (let p in district) {
    temp_dis[p] = {
      value: district[p].id,
      label: district[p].name.substring(2),
    };
    //console.log("Temporary Variable", temp[p])
    districtOptions.push(temp_dis[p]);
  }

  //Multi Select Municipality OptionSet
  let municipalityOptions = [];
  let temp_mun = [];
  for (let p in municipality) {
    temp_mun[p] = {
      value: municipality[p].id,
      label: municipality[p].name.substring(2),
    };
    //console.log("Temporary Variable", temp[p])
    municipalityOptions.push(temp_mun[p]);
  }

  //Multi Select Ward OptionSet
  let wardOptions = [];
  let temp_ward = [];
  for (let p in ward) {
    temp_ward[p] = { value: ward[p].id, label: ward[p].name.substring(2) };
    //console.log("Temporary Variable", temp[p])
    wardOptions.push(temp_ward[p]);
  }

  //Multi Select Health Facility OptionSet
  let healthFacilityOptions = [];
  let temp_healthFacility = [];
  for (let p in healthFacility) {
    temp_healthFacility[p] = {
      value: healthFacility[p].id,
      label: healthFacility[p].name.substring(0),
    };
    //console.log("Temporary Variable", temp[p])
    healthFacilityOptions.push(temp_healthFacility[p]);
  }

  //editData && console.log("Test","1"===editData.user_type)

  // const provinceOptions=[
  //     {value:'0',label:'Province 1'},
  //     {value:'1',label:'Province 2'},
  //     {value:'2',label:'Province 3'},
  //     {value:'3',label:'Province 4'},
  //     {value:'4',label:'Province 5'},
  //     {value:'5',label:'Province 6'},
  //     {value:'6',label:'Province 7'}
  // ]
  //usedProvince[0] && console.log("I am here", usedProvince[0].value)

  useEffect(() => {
    if (editData && province) {
      const selectedProvince = province
        .filter(
          (provinceItem) => editData.province.indexOf(provinceItem.id) > -1
        )
        .map((provinceItem) => ({
          value: provinceItem.id,
          label: provinceItem.name.substring(2),
        }));
      setUsedProvince(selectedProvince);
    }
  }, [editData, province]);

  useEffect(() => {
    if (editData && district) {
      //IS not
      const selectedDistrict = district
        .filter(
          (districtItem) => editData.district.indexOf(districtItem.id) > -1
        )
        .map((districtItem) => ({
          value: districtItem.id,
          label: districtItem.name.substring(2),
        }));
      setUsedDistrict(selectedDistrict);
    }
  }, [editData, district]);

  useEffect(() => {
    if (editData && municipality) {
      const selectedMunicipality = municipality
        .filter(
          (municipalityItem) =>
            editData.municipality.indexOf(municipalityItem.id) > -1
        )
        .map((municipalityItem) => ({
          value: municipalityItem.id,
          label: municipalityItem.name.substring(2),
        }));
      setUsedMunicipality(selectedMunicipality);
    }
  }, [editData, municipality]);

  useEffect(() => {
    if (editData && ward) {
      const selectedWard = ward
        .filter((wardItem) => editData.wards.indexOf(wardItem.id) > -1)
        .map((wardItem) => ({
          value: wardItem.id,
          label: wardItem.name.substring(2),
        }));

      setUsedWard(selectedWard);
    }
  }, [editData, ward]);

  useEffect(() => {
    if (editData && healthFacility) {
      const selectedHealthFacility = healthFacility
        .filter(
          (healthFacilityItem) =>
            editData.hi.indexOf(healthFacilityItem.id) > -1
        )
        .map((healthFacilityItem) => ({
          value: healthFacilityItem.id,
          label: healthFacilityItem.name.substring(2),
        }));

      setUsedHealthFacility(selectedHealthFacility);
    }
  }, [editData, healthFacility]);

  const toggleCheckedMtb = () => {
    setCheckedMtb(!checkedMtb);
  };

  const toggleCheckedCovid = () => {
    setCheckedCovid(!checkedCovid);
  };

  return (
    <ContainerBox>
      <Header title={editData ? "Edit GX Users" : "Add GX Users"} />
      <div className="addgxsite">
        <form onSubmit={handleSubmit(onsubmit)}>
          <div className="addgxsite-form">
            <div className="addgxsite-form-row">
              <div className="addgxsite-form-name form">
                <label>Username</label>
                <input
                  autoComplete="off"
                  name="username"
                  disabled={editData}
                  defaultValue={editData ? editData.username : null}
                  ref={register({ required: true })}
                  className={`${errors.name && "showerror"} `}
                />
              </div>
              {!editData && (
                <div className="addgxsite-form-name form">
                  <label>Password</label>
                  <div className="passowrd-container">
                    <input
                      autoComplete="new-password"
                      name="password"
                      type={type}
                      // defaultValue={editData ? editData.password : null}
                      ref={register({ required: editData ? false : true })}
                      className={`${errors.name && "showerror"} `}
                    />
                    <div className="passowrd-eye">
                      {type === "password" ? (
                        <IoMdEye onClick={() => setType("text")} size={24} />
                      ) : (
                        <IoMdEyeOff
                          onClick={() => setType("password")}
                          size={24}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="addgxsite-form-row">
              <div className="addgxsite-form-name form">
                <label>First Name</label>
                <input
                  name="first_name"
                  defaultValue={editData ? editData.first_name : null}
                  ref={register({ required: true })}
                  className={`${errors.name && "showerror"} `}
                />
              </div>
              <div className="addgxsite-form-name form">
                <label>Last Name</label>
                <input
                  name="last_name"
                  defaultValue={editData ? editData.last_name : null}
                  ref={register({ required: true })}
                  className={`${errors.name && "showerror"} `}
                />
              </div>
            </div>
            <div className="addgxsite-form-row">
              <div className="addgxsite-form-name form">
                <label>Email</label>
                <input
                  name="email"
                  defaultValue={editData ? editData.email : null}
                  ref={register({
                    required: true,
                    pattern:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  className={`${errors.name && "showerror"} `}
                />
              </div>

              <div className="addgxsite-form-name form">
                <label>Phone Number</label>
                <input
                  name="phoneNumber"
                  defaultValue={editData ? editData.phone_number : null}
                  ref={register()}
                  className={`${errors.name && "showerror"} `}
                />
              </div>
            </div>

            <div className="addgxsite-form-row">
              <div className="addgxsite-form-site form">
                <label>User Type</label>
                <select
                  name="user_type"
                  defaultValue={() => {
                    getDefaultValue();
                  }}
                  ref={register({ required: true })}
                  className={`${errors.province && "showerror"}`}
                >
                  <option value="">Select</option>
                  {userRole !== "3" && <option value="1">Admin</option>}
                  <option value="2">Central</option>
                  <option value="3">Engineer</option>
                  <option value="4">Provincial</option>
                  <option value="5">District</option>
                  <option value="6">GX Sites</option>
                </select>
              </div>
              <div className="addgxsite-form-site form">
                <label>Province</label>
                <Select
                  name="province"
                  // defaultValue={editData ? editData.province[0] : null}
                  //value={usedProvince}
                  onChange={setUsedProvince}
                  options={provinceOptions}
                  value={usedProvince}
                  isMulti

                  // options={province && province.map((item, index) => {
                  //     return <option value={item.id} key={index}>{(item.name).substring(2)}</option>
                  // })}
                />
              </div>
            </div>

            <div className="addgxsite-form-row">
              <div className="addgxsite-form-site form">
                <label>District</label>
                <Select
                  name="district"
                  value={usedDistrict}
                  onChange={setUsedDistrict}
                  options={districtOptions}
                  isMulti
                />
              </div>
              <div className="addgxsite-form-site form">
                <label>Municipality</label>
                <Select
                  name="municipality"
                  value={usedMunicipality}
                  onChange={setUsedMunicipality}
                  options={municipalityOptions}
                  isMulti
                />
              </div>
            </div>
            <div className="addgxsite-form-row">
              <div className="addgxsite-form-site form">
                <label>Ward</label>
                <Select
                  name="ward"
                  value={usedWard}
                  onChange={setUsedWard}
                  options={wardOptions}
                  isMulti
                />
              </div>
              <div className="addgxsite-form-site form">
                <label>Health Facilities</label>
                <Select
                  name="health_facility"
                  value={usedHealthFacility}
                  onChange={setUsedHealthFacility}
                  options={healthFacilityOptions}
                  isMulti
                />
              </div>
            </div>
            <div className="addgxsite-form-row">
              <div className="editmachinestatus-form-site form">
                <FormControlLabel
                  //disabled={(editData)}
                  style={{ marginTop: 20 }}
                  control={
                    <Switch
                      checked={checkedMtb}
                      onChange={toggleCheckedMtb}
                      name="is_mtb_results"
                      inputRef={register}
                      color="primary"
                    />
                  }
                  label="Can View MTB Results"
                />
              </div>

              <div className="editmachinestatus-form-site form">
                <FormControlLabel
                  //disabled={(editData)}
                  style={{ marginTop: 20 }}
                  control={
                    <Switch
                      checked={checkedCovid}
                      onChange={toggleCheckedCovid}
                      name="is_covid_results"
                      inputRef={register}
                      color="primary"
                    />
                  }
                  label="Can View Covid Results"
                />
              </div>
            </div>

            <div className="addgxsite-form-row">
              <div className="editmachinestatus-form-site form">
                <FormControlLabel
                  //disabled={(editData)}
                  style={{ marginTop: 20 }}
                  control={
                    <Switch
                      checked={checkedGetSMS}
                      onChange={() => {
                        setCheckedGetSMS((prev) => !prev);
                      }}
                      name="canGetSMS"
                      inputRef={register}
                      color="primary"
                    />
                  }
                  label="Can Get SMS"
                />
              </div>

              <div className="editmachinestatus-form-site form">
                <FormControlLabel
                  //disabled={(editData)}
                  style={{ marginTop: 20 }}
                  control={
                    <Switch
                      checked={checkedGetNotification}
                      onChange={() => {
                        setCheckedGetNotification((prev) => !prev);
                      }}
                      name="canGetNotification"
                      inputRef={register}
                      color="primary"
                    />
                  }
                  label="Can Get Notification"
                />
              </div>
            </div>

            <div className="addgxsite-form-row">
              <div className="editmachinestatus-form-site form">
                <FormControlLabel
                  //disabled={(editData)}
                  style={{ marginTop: 20 }}
                  control={
                    <Switch
                      checked={checkedViewUltra}
                      onChange={() => {
                        setCheckedViewUltra((prev) => !prev);
                      }}
                      name="canViewUltra"
                      inputRef={register}
                      color="primary"
                    />
                  }
                  label="Can View Ultra"
                />
              </div>

              <div className="editmachinestatus-form-site form">
                <FormControlLabel
                  //disabled={(editData)}
                  style={{ marginTop: 20 }}
                  control={
                    <Switch
                      checked={checkedViewXdr}
                      onChange={() => {
                        setCheckedViewXdr((prev) => !prev);
                      }}
                      name="canViewXdr"
                      inputRef={register}
                      color="primary"
                    />
                  }
                  label="Can View Xdr"
                />
              </div>
            </div>

            <div className="addgxsite-form-row">
              {editData ? (
                <Button type="submit" variant="contained" color="primary">
                  Update
                </Button>
              ) : (
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              )}
              <Button
                onClick={cancelHandler}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
              {/* <Button onClick={getDefaultProvince}>Check</Button> */}
            </div>
          </div>
        </form>
      </div>
    </ContainerBox>
  );
};
export default AddUsers;
