export const APIS = {
  sample: "/sample",
  login: "/api/login",
  total_machine_results: "/api/results/count/",
  total_gx_machines: "/api/machines/count/",
  total_gx_counts: "/api/sites/count/",
  machine_online: "/api/results/machine-online/",
  expiring_machine: "/api/machines/expire_machine/",
  expiring_machineCSV: "/api/machines/csv_expire_machine/",
  calibration_machine: "/api/machines/expire_callib_machine/",
  calibration_machineCSV: "/api/machines/csv_expire_callib_machine/",
  machine_response: "/api/machines/",
  machineserialno: "/api/machineserialno/",
  sites_response: "/api/sites/",
  site_wise_working_modules_status:
    "/api/sites/site_wise_working_module_status/",
  site_wise_rate_analysis: "/api/results/site_wise_rate_analysis/",
  get_csv_site_wise_rate_analysis:
    "/api/results/get_csv_site_wise_rate_analysis/",
  site_map_result: "/api/sites/site_map/",
  machine_status_result: "api/results/get_machine/",
  site_list: "api/sites/gx_list/",
  ticket: "/api/tickets/",
  ticketCSV: "/api/tickets/get_csv_ticket/",
  ticket_data: "/api/tickets/ticket_data",
  ticket_status_change: "/api/tickets/change_status/",
  ticket_comment: "/api/ticketcomments/",
  ticket_search: "/api/ticket/search/",
  machine_result: "api/results/",
  covid_result: "/api/resultscovid/",
  ultra_result: "/api/ultraresult/",
  xdr_result: "/api/xdrresult/",

  get_report_data: "api/results/get_report/",
  get_covid_report_data: "/api/resultscovid/get_report/",
  get_province: "/api/provinces/",
  get_district: "/api/provinces/get_district/",
  get_municipalites: "/api/district/get_municipality/",
  get_ward: "/api/municipality/get_wards/",
  get_health_facility: "/api/wards/get_hi/",
  users: "/api/users/",
  getSiteUsers: "/api/users/get_users_given_site/",
  change_password: "/api/users/change_password/",
  reset_password: "/api/users/force-change-password/",
  notification: "/api/usernotifications/",
  notification_threshold: "/api/results/calibration_threshold/",
  chartData: "/api/results/chart_data/",

  machinenotworkingdata: "/api/machinenotworkingdata/get_machine_data",
  modulenotworkingdata: "/api/modulenotworkingdata/get_module_data",
  moduleHistory: "/api/modulehistory",
  sitenotworkingdata: "/api/sitenotworkingdata/get_site_data",

  dashboardAnalysis: "/api/results/dashboard_analysis/",
  force_change_password: "/api/users/force_change_password/",
};
